<template>
    <div>
        <div v-if="code">
            <div class="success">
                <img src="../../../public/images/dui.png" alt="">
            </div>
            <div>
                <p class="accreditOk">绑定成功</p>
            </div>
        </div>
        <div v-if="status">
            <div class="success">
                <img src="../../../public/images/X.png" alt="">
            </div>
            <div>
                <p class="accreditOk">{{msg}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                code:false,
                openid:"",
                status:false,
                msg:"",
            }
        },
        methods:{
            lodData(){
                console.log(window.location.href)
                this.$http.post('/index.php/wechat/redirect_uri',{url:window.location.href,app_id:this.$route.query.app_id}).then(res=>{
                    console.log(res)
                    if(res.data.code==0){
                        console.log(res.data)
                        this.code = true
                    }else if(res.data.code==2){
                        // console.log(res.data)
                        location.href = res.data.data
                    }else if(res.data.code==3){
                        this.status = true
                        this.msg = res.data.msg
                    }
                    // if(res.code!==0){
                    //     this.status = true
                    //     this.msg = res.msg
                    // }else{
                    //     this.code = true
                    // }
                    // if(res.code == 0) {
                    //     if(res.data.openid){
                    //         this.openid = res.data.openid
                    //     }else{
                    //         location.href = res.data.url
                    //     }
                    // }
                })
            }
        },
        created () {
            this.lodData();
        },
    }
</script>

<style lang="scss" scoped>
.accreditOk{
    font-weight: bold;  
    font-size: 20px;
    text-align: center;
}
.success{
    text-align: center;
    margin: 100px 0 50px 0;
}
</style>